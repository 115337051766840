@font-face {
    font-family: minionBold;
    src: url(../fonts/minionproBold.otf) format("otf");
  }
  @font-face {
    font-family: minion;
    src: url(../fonts/minionpro.otf) format("otf");
  }

.wrapper {
    margin-left: 278px;
    margin-right: 278px;
    .ballDiv {
        margin-top: 29px;
        height: 75px;
        width: 150px;
        cursor: pointer;
        margin-bottom: 200px;
  
        .clickDiv {
          display: flex;
          flex-direction: row;
          width: 150px;
          .ball {
            height: 75px;
            opacity: 0.3;
            border: 1px solid #ffffff;
            transform: matrix(1, 0, 0, -1, 0, 0);
            border-radius: 50px;
            width: 75px;
          }
          .active {
            transition: 0.3s;
            opacity: 0.3;
            border: 1px solid #ffffff;
            transform: matrix(1, 0, 0, -1, 0, 0);
            border-radius: 50px;
            width: 30px;
            height: 29px;
          }
        }
    }
    .text {
        h1{
            font-family: minion;
            margin-bottom: 0;
        }
        p {
            font-family: minion;
            font-size: 25px;
            line-height: 38px;
        }
    }
}