.modal {
    height: 100vh;
    width: 100vw;
    background-color: rgba(0,0,0,0.4);
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: scale(0);

    .modalContent{
        padding: 20px;
        border-radius: 12px;
        background-color: white;
        width: 750px;
        height: 420px;
    }
}
.active {
        transform: scale(1);
}