@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100&display=swap");
@font-face {
  font-family: minionBold;
  src: url(../fonts/minionproBold.otf) format("otf");
}
@font-face {
  font-family: minion;
  src: url(../fonts/minionpro.otf) format("otf");
}

body {
  background-color: black;
}
p {
  margin: 0;
  padding: 0;
}

.App {
  color: #ffffff;
  .video {
    width: 100%;
  }
  .content {
    margin-right: auto;
    margin-left: auto;
    max-width: 1650px;
    .bio {
      margin-top: 95px;
      display: flex;
      justify-content: space-between;
      height: 424px;
      .picture {
        margin-right: 16px;
        width: 398px;
        height: 424px;
      }
      p {
        font-family: minion;
        font-style: normal;
        font-weight: 400;
        font-size: 22px;
        line-height: 28px;
      }
    }
    .upperground {
      margin-top: 35px;
      h1 {
        color: #e16150;
        font-family: "Inter";
        font-style: normal;
        font-weight: 100;
        font-size: 100px;
        margin-block-start: 0px;
        margin-block-end: 0px;
        line-height: 121px;
        letter-spacing: 0.9em;
      }
    }
    .ballDiv {
      margin-top: 29px;
      height: 75px;
      display: flex;
      justify-content: center;
      align-items: center;

      .clickDiv {
        display: flex;
        flex-direction: row-reverse;
        cursor: pointer;
        width: 150px;
        .ball {
          height: 75px;
          background-color: #30fa30;
          opacity: 1;
          border: 1px solid #ffffff;
          transform: matrix(1, 0, 0, -1, 0, 0);
          border-radius: 50px;
          width: 75px;
        }
        .active {
          transition: 0.3s;
          opacity: 0.3;
          background-color: #30fa30;
          border: 1px solid #ffffff;
          transform: matrix(1, 0, 0, -1, 0, 0);
          border-radius: 50px;
          width: 30px;
          height: 29px;
        }
      }
    }
    .bio2 {
      display: flex;
      margin-top: 163px;
      justify-content: space-between;
      margin-bottom: 52px;
      .music {
        width: 557px;
        .musicPost {
          margin-top: 29px;
          display: flex;
          .left {
            cursor: pointer;
            .playDiv {
              display: flex;
              justify-content: center;
              width: 222px;
              height: 222px;
              align-items: center;
              position: absolute;
              .pause {
                width: 80px;
                position: absolute;
                z-index: 2;
                display: none;
              }
              .active {
                display: block;
              }
              .play {
                width: 50px;
                position: absolute;
                z-index: 2;
              }
            }
            
            .poster {
              position: relative;
              width: 222px;
              height: 222px;
              margin-right: 54px;
            }
            .poster:hover {
              filter: brightness(0.5) ;
            }

          }
          .right {
            .name {
              margin-bottom: 17px;
            }
            h1 {
              display: inline;
              font-family: minion;
              margin: 0;
              height: 31px;
              width: 112px;
            }
          }
        }
      }
      .exhebitions {
        width: 800px;
        h1 {
          font-family: "Inter";
          font-size: 40px;
        }
        p {
          font-family: "Inter";
          font-style: normal;
          font-weight: 400;
          font-size: 22px;
        }
        .manEx {
          margin-top: 10px;
        }
      }
    }
    .slider {
      .picture {
        @keyframes fadeIn {
          0% {
            opacity: 0;
          }
          100% {
            opacity: 1;
          }
        }

        animation: fadeIn 5s;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      img {
        width: auto;
        height: 750px;
      }
      .buttons {
        display: flex;
        margin-top: 20px;
      }
      .prev {
        margin-right: 930px;
        display: flex;
        align-items: center;
        .disc{
          font-size: 25px;
          color: #e16150;
          font-family: "Inter";
          margin-left: 50px;
          width: 500px;
        }
      }
      .clickDiv {
       
        width: 75px;
        height: 75px;
        cursor: pointer;
        .ball {
          height: 75px;
          opacity: 1;
          border: 1px solid #ffffff;
          transform: matrix(1, 0, 0, -1, 0, 0);
          border-radius: 50px;
          width: 75px;
          background-color: #30fa30;
        }
        .active {
          transition: 0.3s;
          opacity: 0.3;
          background-color: #30fa30;
          border: 1px solid #ffffff;
          transform: matrix(1, 0, 0, -1, 0, 0);
          border-radius: 50px;
          width: 30px;
          height: 29px;
        }
      }
    }
    .red {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .footer {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 256px;
      margin-bottom: 500px;
      .footer-content {
        width: 812px;
      }
      .social {
        display: flex;
        justify-content: space-between;
        h1 {
          cursor: pointer;
          font-family: minion;
          font-weight: 700;
          width: 100px;
          height: 40px;
        }
      }
      .contact {
        display: flex;
        justify-content: space-between;
        p {
          font-family: minion;
          line-height: 31px;
          font-size: 20px;
        }
      }
    }
  }
}

@media only screen and (max-width: 1200px) {
  .App {
    .content {
      .upperground {
        h1 {
          font-size: 5vw;
          text-align: center;
        }
      }
      .bio {
        flex-direction: column;
        height: auto;
        align-items: center;
        .picture {
          margin-bottom: 15px;
        }
      }
      .bio2 {
        flex-direction: column-reverse;
        align-content: center;
        justify-content: center;
        .music {
          width: auto;
        }
      }
      .slider {

        img {
          height: 500px;
        }
        .buttons {
          width: 100%;
          display: flex;
          justify-content: space-between;
          .prev {
            margin-right: 0px
          }
        }
      }
      }
    }
  
}

